import { AppBar, Box, Button, Dialog, IconButton, Menu, MenuItem, styled, Typography } from '@mui/material'
import { ROLE } from 'api/entities/user'
import { CloseIcon, ExitIcon } from 'assets/svg'
import { ArrowDownFilled } from 'assets/toggle'
import { logout, setConsole } from 'modules/auth/actions'
import { selectAuth } from 'modules/auth/slice'
import { accessFor, getOptions } from 'modules/common/components/Header/utils'
import { Logo } from 'modules/common/components/Logo'
import { HEIGHT } from 'modules/common/constants'
import { ROUTE } from 'modules/common/routes'
import { useText } from 'modules/locale'
import { Locale } from 'modules/locale/components'
import { selectMe } from 'modules/me/slice'
import React, { FC, MouseEvent, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { color } from 'styles/theme'
import { ADMIN_TABS, KNOWLEDGE_LINK, RIGHT_TABS, USER_TABS } from './constants'

type Props = { hidden?: boolean }
type Tabs = typeof ADMIN_TABS & typeof USER_TABS

export const Header: FC<Props> = ({ hidden }) => {
  const [open, setOpen] = useState(false) // open for Dialog
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null) // open for Menu
  const { text } = useText('HEADER')
  const { pathname } = useLocation()
  const { consoleFor, isAuth } = useSelector(selectAuth)
  const { my } = useSelector(selectMe)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const active = useMemo(() => pathname.slice(1) ? pathname.slice(1) : 'main', [pathname])
  const TABS = useMemo(() => consoleFor === ROLE.ADMIN ? ADMIN_TABS : USER_TABS, [consoleFor]) as Tabs

  if (hidden) {
    return null
  }

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    const { id } = e.currentTarget
    navigate(ROUTE[id as keyof typeof ROUTE])
  }
  // open Dialog
  const handleOpen = () => {
    setOpen(true)
    handleCloseMenu()
  }
  const handleClose = () => setOpen(false) // close Dialog

  const handleOpenMenu = (e: MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget)
  const handleCloseMenu = () => setAnchorEl(null)
  const handleChooseConsole = (e: MouseEvent<HTMLLIElement>) => {
    dispatch(setConsole(e.currentTarget.id as ROLE))
    navigate(ROUTE.MAIN)
    handleCloseMenu()
  }

  const handleSignOut = () => {
    dispatch(logout())
    navigate(ROUTE.MAIN)
  }

  return (
    <>
      <AppBar position='sticky' color='default' sx={{ minWidth: '1400px' }}>
        <Box width='1400px' p='0 60px 0 100px' display='flex' mx='auto' alignItems='center'>
          <Logo />&nbsp; educational
          {!isAuth ? (
            <Action sx={{ ml: 'auto' }} id='SIGN_IN' variant='text' disabled onClick={handleClick} />
          ) : (
            <>
              <Box ml='40px' mr='auto'>
                {Object.keys(TABS).map((it) => {
                  return (
                    <Action
                      key={it}
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      target={TABS[it as keyof typeof TABS] === TABS.KNOWLEDGE_BASE ? '_blank' : undefined}
                      href={TABS[it as keyof typeof TABS] === TABS.KNOWLEDGE_BASE ? KNOWLEDGE_LINK : undefined}
                      id={it}
                      checked={Boolean(active === TABS[it as keyof typeof TABS])}
                      variant='text'
                      onClick={TABS[it as keyof typeof TABS] === TABS.KNOWLEDGE_BASE ? undefined : handleClick}
                    >
                      {text[TABS[it as keyof typeof TABS]]}
                    </Action>
                  )
                })}
              </Box>

              <Locale />

              <Box ml='20px'>
                {Object.keys(RIGHT_TABS).map((it) => {
                  return (
                    <Action
                      key={it}
                      id={it}
                      checked={Boolean(active === RIGHT_TABS[it as keyof typeof RIGHT_TABS])}
                      variant='text'
                      onClick={it === 'SIGN_OUT' ? handleOpen : handleClick}
                    >
                      {text[RIGHT_TABS[it as keyof typeof RIGHT_TABS]]}
                    </Action>
                  )
                })}

                <Action
                  id='SIGN_OUT'
                  variant='text'
                  endIcon={accessFor(my?.accessRights) === ROLE.USER ? null : <ArrowDownFilled />}
                  onClick={accessFor(my?.accessRights) === ROLE.USER ? handleOpen : handleOpenMenu}
                >
                  <Box display='flex' gap='5px' alignItems='center'>
                    {text.signOut}<ExitIcon />
                  </Box>

                </Action>

              </Box>
            </>
          )}

        </Box>
      </AppBar>

      {my && (
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: -10, horizontal: 'right' }}
          onClose={handleCloseMenu}
        >
          {getOptions(my.accessRights).map(it => {
            return (
              <StyledItem
                key={it.consoleFor}
                selected={it.consoleFor === consoleFor}
                id={it.consoleFor || undefined}
                onClick={it.consoleFor ? handleChooseConsole : handleOpen}
              >
                {it.label}
              </StyledItem>
            )
          })}
        </Menu>
      )}

      <Dialog open={open} onClose={handleClose}>
        <StyledIconButton size='small' onClick={handleClose}>
          <CloseIcon />
        </StyledIconButton>

        <Typography variant='h2'>{text.signOut}</Typography>
        <Typography my='20px' variant='body1' color='secondary'>{text.sureToSignOut}</Typography>

        <Box display='flex' gap='20px' mt='20px'>
          <Button fullWidth variant='contained' onClick={handleSignOut}>{text.signOut}</Button>
          <Button autoFocus fullWidth variant='outlined' onClick={handleClose}>{text.cancel}</Button>
        </Box>
      </Dialog>
    </>
  )
}

const Action = styled(Button)<{ checked?: boolean }>`
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  height: ${HEIGHT.HEADER};
  padding: 20px 40px;
  border-radius: 5px;

  &.MuiButton-root {
    color: ${({ checked }) => checked ? color.high : color.medium};
    position: relative;

    &:after {
      padding: 0 40px;
      content: '';
      width: calc(100% - 70px);
      height: ${({ checked }) => checked ? '1px' : 0};
      border-radius: 1px;
      position: absolute;
      background: ${color.success};
      top: 38px;
    }
  }

  &[disabled] {
    color: ${color.medium}
  }
`

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`

const StyledItem = styled(MenuItem)`
  font-weight: ${({ selected }) => selected ? 500 : 'normal'};
  color: ${({ selected }) => selected ? color.high : color.medium};
  padding: 5px 10px;

  &:first-of-type {
    margin-top: 5px;
  }

  &:last-child {
    margin-bottom: 5px;
  }
`
